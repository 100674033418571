import React from 'react';

const Feedback = () => {

  return (
    <div style={{margin: '1rem'}}>
      <h2>Do you have any questions or concerns about this interactive map?</h2>

      <h3>Please <a href='mailto:cupr-server-alert@ejb.rutgers.edu' target={'_blank'} rel='noreferrer'>provide feedback</a>.</h3>
    </div>
  )
}

export default Feedback